
import {defineComponent, PropType} from 'vue';
import TableItem from '@/components/TableItem.vue';

export default defineComponent({
  name: 'ServersTable',
  props: {
    servers: {
      type: Array as PropType<Array<StatusItem | BoxItem>>,
      default: []
    }
  },
  components: {
    TableItem
  }
});
